//
// Head entry file
// Should be inserted inside <head>
// --------------------------------------------------

// Init lazysizes
import 'lazysizes/plugins/object-fit/ls.object-fit.js';
import 'lazysizes';

// Init scrollreveal
import scrollReveal from 'scrollreveal';
window.sr = scrollReveal();
